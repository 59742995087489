@if (label()) {
  <label
    class="at-mb-x-sm"
    [ngClass]="{
      'at-text-neutral-300': disabled(),
      'at-text-neutral-900 at-fw-medium': !disabled(),
    }"
    [for]="id()"
    >{{ label() }}
  </label>
}

<!-- Input Container -->
<div class="at-position-relative at-flex at-align-center">
  @if (startIcon()) {
    <div class="icon at-inset-inline-start-md">
      <img [src]="startIcon()" alt="start-icon" />
    </div>
  }

  <input
    [type]="type()"
    [id]="id()"
    [name]="name()"
    [readOnly]="readonly()"
    [formControlName]="inputFormControlName()"
    [required]="required()"
    [min]="min()"
    [max]="max()"
    [attr.minlength]="minlength()"
    [attr.maxlength]="maxlength()"
    [value]="value() ?? null"
    [placeholder]="placeholder()"
    [autocomplete]="autocomplete()"
    (keypress)="
      type() === 'number'
        ? $event.charCode >= 48 && $event.charCode <= 57
        : true
    "
    class="at-w-100 at-border at-border-neutral-300 at-radius-sm at-drop-shadow-xs at-text-sm at-text-neutral-900 at-ps-md at-pe-md"
    [class]="inputClass()"
    [ngClass]="{
      'has-errors at-text-destructive-900':
        childControl.touched && childControl.dirty && childControl.errors,
    }"
    [class.at-ps-5xl]="startIcon()"
    [class.at-pe-5xl]="endIcon()" />

  @if (endIcon()) {
    <div
      (click)="applyActionOnEndIconClicked()"
      class="icon at-inset-inline-end-md at-cursor-pointer">
      <img [src]="endIcon()" alt="end-icon" />
    </div>
  }
</div>
